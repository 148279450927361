import {inject, observer} from "mobx-react";
import moment from "moment";
import * as React from "react";

import {
    faCalendarPlus,
    faCloudUploadAlt,
    faDownload,
    faEdit,
    faPlus,
    faRedoAlt, faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {ApplicationStore} from "../../stores/ApplicationStore";
import {SimpleConfirmation} from "./SimpleConfirmAlert";
import {InputGroup, SelectGroup, TextareaGroup} from "./SimpleFormComponents";
import {errorMessage, successMessage} from "./Toast";
import {GrowerSubmission, MarketerSubmission} from "../../model/Submission";
import {SubmissionEndpoints} from "../../endpoints/SubmissionEndpoints";
import {Commodity, CommodityFriendlyName, ProprietaryCommodity} from "../../model/ProprietaryCommodity";
import {
    CommodityDetail,
    CommodityDetailType,
    CommoditySquareFootCommodity,
    Crop,
    Propagator,
    YearWeek,
    YearWeekToMoment
} from "../../model/CropProductionDetails";
import {AreaUnit, YieldUnit} from "../../model/Configuration";
import {CropProductionRequest} from "../../requests/CropProductionRequest";
import CropProductionDetailsTableWidget from "./CropProductionDetailsTableWidget";
import {FormDialog} from "./Dialog";
import LoadingWidget from "./Loading";
import {DownloadBlob, FileToBase64} from "../../assets/Common/FileUtil";
import AttachmentWidget from "./AttachmentWidget";
import {ProprietaryCommodityRequest} from "../../requests/ProprietaryCommodityRequest";
import {AttachmentRequest} from "../../requests/AttachmentRequest";
import {AttachmentFile} from "../../model/Attachments";
import Dropzone from "react-dropzone";
import {UncontrolledTooltip} from "reactstrap";

// NOTE: This file has an analogue in the `ogvg-mrm-shell/mrm-second-gen`. Any fixes or updates may need to be applied in both.

const SEEDLESS_CUCUMBERS_NAME = "Seedless Cucumbers";
const ENGLISH_CUCUMBERS_NAME = "English";
const _10lbsUnits = ["Mini Peppers", "Shepherd", "Cocktail", "Heirloom"];
const _75lbsUnits = ["Grape", "Cherry"];
const _25lbsUnits = ["Roma"]

export interface YearWeekOption {
    year: number;
    week: number;
    startDay: string;
    finishDay: string;
}

export interface Fields {
    id: number | null;

    varietyId: number | null; // This should be an ID....
    varietyType: Commodity | null;
    commoditySizeId: number | null;
    commodityColorId: number | null;
    commodityContainerId: number | null;
    proprietaryVarietyId: number | null;
    organic: boolean;
    proprietary: boolean;

    rangeName: string;
    seedlingVariety: string;

    plantYearWeek: YearWeek | null;
    pullYearWeek: YearWeek | null;
    harvestYearWeek: YearWeek | null;

    enteredArea: string;
    preferredUnit: string | null;
    enteredExpectedYield: string;
    enteredExpectedYieldUnit: string | null;

    numberOfPlants: string;
    numberOfHeadsPerPlant: string;
    cucumberHighwire: boolean;

    primaryPropagatorId: number | null;

    notes: string;
    underLights: boolean | null;
}

export interface ProprietaryFields {
    proprietaryFieldId: number | null;
    //I've nulled all of these since we're add
    type: Commodity | null;
    variety: string;
    seedCompanyProvider: string | null;
    seedCompanyProviderMainContact: string | null;
    seedCompanyAddress: string | null;
    seedCompanyPhoneNumber: string | null;
    seedCompanyEmailAddress: string | null;
    proprietaryCode: string | null;
    protectedVariety: boolean | null;
    exclusiveCommodity: boolean | null;
    exclusivityNotes: string | null;
    contractEstablished: boolean | null;
    contractTimeFrameMonths: number | null;
    annualAcresToSeed: number | null;
    transferrableAgreement: boolean | null;
    proprietaryFiles: Array<AttachmentRequest>;
}

export interface ComponentState {
    wasValidated: boolean;
    showDialog: boolean;
    showPropDialog: boolean;
    newUpload: boolean;
    isRotation: boolean;
}

interface Props {
    applicationStore?: ApplicationStore;
    submissionEndpoints?: SubmissionEndpoints;
}

type State = ComponentState & Fields & ProprietaryFields;
@inject("submissionEndpoints")
@inject("applicationStore")
@observer
export default class CropProductionDetailsWidget extends React.Component<Props, State> {
    private form: HTMLFormElement | null;
    private proprietaryForm: HTMLFormElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            ...this.defaultState()
        };
    }

    defaultState = () => {
        return {
            ...this.emptyFields(),
            ...this.emptyProprietaryFields(),
            wasValidated: false,
            showDialog: false,
            isRotation: false,
            showPropDialog: false,
            newUpload: false
        };
    };

    emptyFields = (): Fields => {
        return {
            id: null,

            varietyType: null,
            varietyId: null,
            commoditySizeId: null,
            commodityColorId: null,
            commodityContainerId: null,
            proprietaryVarietyId: null,
            organic: false,
            proprietary: false,

            rangeName: "",
            seedlingVariety: "",

            plantYearWeek: null,
            pullYearWeek: null,
            harvestYearWeek: null,

            enteredArea: "",
            preferredUnit: null,
            enteredExpectedYield: "",
            enteredExpectedYieldUnit: null,

            numberOfPlants: "",
            numberOfHeadsPerPlant: "",
            cucumberHighwire: false,

            primaryPropagatorId: null,

            notes: "",
            underLights: false
        };
    };

    fromModel = (m: Crop): Fields => {
        const newState: Fields = {
            id: m.id,

            varietyId: m.variety ? m.variety.id : null,
            varietyType: m.variety ? m.variety.type as Commodity : null,
            commoditySizeId: m.sizeDetail ? m.sizeDetail.id : null,
            commodityColorId: m.colorDetail ? m.colorDetail.id : null,
            commodityContainerId: m.containerDetail ? m.containerDetail.id : null,
            proprietaryVarietyId: m.proprietaryVariety ? m.proprietaryVariety.id : null,
            organic: m.organic,
            proprietary: m.proprietary,

            rangeName: m.rangeName,
            seedlingVariety: m.seedlingVariety,

            plantYearWeek: m.plantYearWeek || null,
            pullYearWeek: m.pullYearWeek || null,
            harvestYearWeek: m.harvestYearWeek || null,

            enteredArea: m.enteredArea.toString(),
            preferredUnit: m.preferredUnit.name,
            enteredExpectedYield: m.enteredExpectedYield.toString(),
            enteredExpectedYieldUnit: m.enteredExpectedYieldUnit.name,

            numberOfPlants: m.numberOfPlants.toString(),
            numberOfHeadsPerPlant: m.numberOfHeadsPerPlant.toString(),
            cucumberHighwire: m.cucumberHighwire,

            primaryPropagatorId: m.primaryPropagator ? m.primaryPropagator.id : null,

            notes: m.notes,
            underLights: m.underLights
        };

        return newState as Fields;
    };

    generateRequest = (): CropProductionRequest => {
        const s = this.state;
        if (
            s.plantYearWeek === null ||
            s.pullYearWeek === null ||
            s.harvestYearWeek === null ||
            s.preferredUnit === null ||
            s.enteredExpectedYieldUnit === null ||
            s.primaryPropagatorId === null ||
            isNaN(parseFloat(s.enteredArea)) ||
            isNaN(parseFloat(s.enteredExpectedYield)) ||
            isNaN(parseFloat(s.numberOfPlants))
        ) {
            throw new Error("In toRequest with an unvalidated request.");
        }
        return {
            varietyId: s.varietyId,
            varietyType: s.varietyType,
            sizeDetailID: s.commoditySizeId,
            colorDetailID: s.commodityColorId,
            containerDetailID: s.commodityContainerId,
            proprietaryVarietyId: s.proprietaryVarietyId,
            organic: s.organic,
            proprietary: s.proprietary,

            rangeName: s.rangeName,
            seedlingVariety: s.seedlingVariety,

            plantYearWeek: s.plantYearWeek,
            harvestYearWeek: s.harvestYearWeek,
            pullYearWeek: s.pullYearWeek,

            enteredArea: parseFloat(s.enteredArea),
            preferredUnit: s.preferredUnit,
            enteredExpectedYield: parseFloat(s.enteredExpectedYield),
            expectedYieldUnit: s.enteredExpectedYieldUnit,

            numberOfPlants: parseInt(s.numberOfPlants, 10),
            numberOfHeadsPerPlant: s.numberOfHeadsPerPlant ? parseInt(s.numberOfHeadsPerPlant, 10) : 0,
            cucumberHighwire: s.cucumberHighwire,

            primaryPropagatorId: s.primaryPropagatorId,

            notes: s.notes,
            underLights: s.underLights
        };
    };

    editCropProductionForm = (m: Crop) => {
        this.setState({...this.fromModel(m), wasValidated: false, showDialog: true});
    };

    editCropProductionWithSameRangeForm = (m: Crop) => {
        this.setState({
            ...this.fromModel(m),
            id: null,
            plantYearWeek: null,
            pullYearWeek: null,
            harvestYearWeek: null,
            isRotation: true,
            wasValidated: false,
            showDialog: true
        });
    };

    CommodityTypes = (): Array<Commodity> => {
        const configuration = this.props.applicationStore!.configuration;
        if (configuration == null)
            return [];
        let arr = Array<Commodity>();
        for (let i = 0; i < configuration.commodities.length; i++) {
            if (!arr.includes(configuration.commodities[i].type as Commodity)) {
                arr.push(configuration.commodities[i].type as Commodity);
            }
        }
        return arr;
    }

    openDialog = () => {
        this.setState({showDialog: true});
    };

    closeDialog = () => {
        this.setState({showDialog: false});
        this.clearForm();
    };

    openPropDialog = () => {
        this.setState({showPropDialog: true, newUpload: false});
    };

    closePropDialog = () => {
        const propID = this.state.proprietaryVarietyId;
        const proprietary = propID !== null && propID > 0;
        this.setState(
            {
                showPropDialog: false,
                proprietary: this.state.newUpload || proprietary,

            });
    };
    closePropDialogPriorUpload = () => {
        const propID = this.state.proprietaryVarietyId;
        const proprietary = propID !== null && propID > 0;
        this.setState(
            {
                showPropDialog: false,
                //proprietary: (this.props.applicationStore!.submission! as GrowerSubmission).growerAttachments.proprietaryCropCert.length > 0
                proprietary: proprietary
            });
    };
    emptyProprietaryFields = (): ProprietaryFields => {
        return {
            proprietaryFieldId: null,
            type: null,
            variety: "",
            seedCompanyProvider: "",
            seedCompanyProviderMainContact: "",
            seedCompanyAddress: "",
            seedCompanyPhoneNumber: "",
            seedCompanyEmailAddress: "",
            proprietaryCode: "",
            protectedVariety: false,
            exclusiveCommodity: false,
            exclusivityNotes: "",
            contractEstablished: false,
            contractTimeFrameMonths: null,
            annualAcresToSeed: null,
            transferrableAgreement: false,
            proprietaryFiles: []
        };
    }
    fromProprietaryModel = (m: ProprietaryCommodity): ProprietaryFields =>{
        const newState : ProprietaryFields = {
            proprietaryFieldId: m.id,
            proprietaryFiles: [],
            type: m.type ? m.type as Commodity : null,
            variety: m.variety ? m.variety : "",
            seedCompanyProvider: m.seedCompanyProvider,
            seedCompanyProviderMainContact: m.seedCompanyProviderMainContact,
            seedCompanyAddress: m.seedCompanyAddress,
            seedCompanyPhoneNumber: m.seedCompanyPhoneNumber,
            seedCompanyEmailAddress: m.seedCompanyEmailAddress,
            proprietaryCode: m.proprietaryCode,
            protectedVariety: m.protectedVariety ? m.protectedVariety : false,
            exclusiveCommodity: m.exclusiveCommodity? m.exclusiveCommodity: false,
            exclusivityNotes: m.exclusivityNotes,
            contractEstablished: m.contractEstablished ? m.contractEstablished : false,
            contractTimeFrameMonths: m.contactTimeFrameMonths ,
            annualAcresToSeed: m.annualAcresToSeed,
            transferrableAgreement: m.transferrableAgreement ? m.transferrableAgreement : false
        }
        return newState as ProprietaryFields;
    }
    generateProprietaryRequest = () : ProprietaryCommodityRequest => {
        const s = this.state;
        if (
            s.varietyType === null ||
            s.seedCompanyProvider === null ||
            s.seedCompanyProviderMainContact === null ||
            s.seedCompanyAddress === null ||
            s.seedCompanyPhoneNumber === null ||
            s.seedCompanyEmailAddress === null ||
            s.proprietaryCode === null ||
            s.protectedVariety === null ||
            s.exclusiveCommodity === null ||
            s.exclusivityNotes === null ||
            s.contractEstablished === null ||
            s.contractTimeFrameMonths === null ||
            s.annualAcresToSeed === null ||
            s.transferrableAgreement === null
            )
        {

            throw new Error("In to Propriety Request with an unvalidated request.");
        }
        return {
            //id: s.proprietaryFieldId,
            type: s.varietyType,
            variety: s.variety,
            notes: "",
            seedCompanyProvider: s.seedCompanyProvider,
            seedCompanyProviderMainContact: s.seedCompanyProviderMainContact,
            seedCompanyAddress:s.seedCompanyAddress,
            seedCompanyPhoneNumber: s.seedCompanyPhoneNumber,
            seedCompanyEmailAddress: s.seedCompanyEmailAddress,
            proprietaryCode: s.proprietaryCode,
            protectedVariety: s.protectedVariety,
            exclusiveCommodity: s.exclusiveCommodity,
            exclusivityNotes: s.exclusivityNotes,
            contractEstablished:s.contractEstablished,
            contactTimeFrameMonths: s.contractTimeFrameMonths,
            annualAcresToSeed: s.annualAcresToSeed,
            transferrableAgreement: s.transferrableAgreement,
            files: s.proprietaryFiles
        }
    }

    clearPropForm = () => {
        this.setState({...this.emptyProprietaryFields()});
    };

    submitProprietary = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (this.proprietaryForm && this.proprietaryForm.checkValidity()) {
            this.setState({wasValidated: false});
            this.state.proprietaryFieldId === null ? this.createProprietary() : this.editProprietary();
        } else {
            this.setState({wasValidated: true});
        }
    };
    createProprietary = async () => {
        try {
            const request = this.generateProprietaryRequest();
            await this.props.applicationStore!.createProprietaryCommodity(request);
            const submission = this.props.applicationStore!.submission as GrowerSubmission;
            const addedPropId = submission.proprietaryCommodities
                .sort(function (a, b) {
                if (a.id < b.id) {
                    return 1;
                } else if (a.id == b.id)
                    return 0;
                else
                    return -1;
                })[0].id;
            this.setState({newUpload: true, proprietaryVarietyId: addedPropId})
            this.closePropDialog();
            successMessage(`A new proprietary variety has been created.`);
        } catch (error) {
            errorMessage(error);
        }
    };
    editProprietary = async () => {
        try {
            const request = this.generateProprietaryRequest();
            if (this.state.proprietaryFieldId === null) {
                throw new Error("In editCropProduction with a null ID?");
            }
            await this.props.applicationStore!.editProprietaryCommodity(this.state.proprietaryFieldId, request as ProprietaryCommodityRequest);
            this.closePropDialog();
            successMessage(`Range successfully updated.`);
        } catch (error) {
            errorMessage(error);
        }
    };
    createCropProduction = async () => {
        try {
            const request = this.generateRequest();
            await this.props.applicationStore!.createCropProduction(request);
            this.closeDialog();
            successMessage(`A new Range has been created.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    editCropProduction = async () => {
        try {
            const request = this.generateRequest();
            if (this.state.id === null) {
                throw new Error("In editCropProduction with a null ID?");
            }
            await this.props.applicationStore!.editCropProduction(this.state.id, request as CropProductionRequest);
            this.closeDialog();
            successMessage(`Range successfully updated.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    deleteCropProduction = async (cropId: number) => {
        try {
            const goAhead = await SimpleConfirmation(<div className="my-2 mx-2"> Are you sure you'd like to delete this
                Range?</div>);
            if (!goAhead) {
                return;
            }
            await this.props.applicationStore!.deleteCropProduction(cropId);
            this.clearForm();
            successMessage(`Range successfully deleted.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    submit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (this.form && this.form.checkValidity()) {
            this.setState({wasValidated: false});
            this.state.id === null ? this.createCropProduction() : this.editCropProduction();
        } else {
            this.setState({wasValidated: true});
        }
    };

    clearForm = () => {
        this.setState({...this.defaultState()});
    };

    createNewRotationWithSameRange = async () => {
        if (this.form && this.form.checkValidity()) {
            this.setState({wasValidated: false});
            this.newRotationWithSameRange();
        } else {
            this.setState({wasValidated: true});
        }
    };

    newRotationWithSameRange = async () => {
        try {
            const request = this.generateRequest();
            await this.props.applicationStore!.createCropProduction(request as CropProductionRequest);
            successMessage(`A new Range has been created.`);
            this.setState({
                plantYearWeek: null,
                pullYearWeek: null,
                isRotation: true
            });
        } catch (error) {
            errorMessage(error);
        }
    };

    handleDefaultValueYieldUnit = (id: number): string => {
        switch (id) {
            case 1:
                return "dzs";
            case 2:
                return "20lb";
            case 9:
                return "15lb";
            case 8:
                return "11lb";
            case 4:
                return "5kg";
            case 5:
                return "5kg";
            case 6:
                return "5kg";
            case 7:
                return "5kg";
            case 15:
                return "5kg";
            default:
                return "lbs";
        }
    };

    /**
     * @param startAt The week to begin the generation
     * @param goLater The default behavious is to go to Week 52 of the licence end year. Setting this to true adds six months to that.
     */
    generateWeekOptions = (startAt: YearWeek | null = null, goLater: boolean = false): Array<YearWeekOption> => {
        const submission = this.props.applicationStore!.submission;
        if (!submission) {
            throw new Error("In Submission");
        }

        const initialDate = moment();
        if (startAt) {
            initialDate
                .isoWeekYear(startAt.year)
                .isoWeek(startAt.week)
                .startOf("week")
                .add(8, "days");
        } else {
            initialDate
                .year(moment(submission!.licenceYear.startDate).year())
                .month(8) // September
                .date(1);
        }

        const lastDate = moment()
            .year(moment(submission!.licenceYear.endDate).year())
            .month(11) // December
            .endOf("month");
        if (goLater) {
            lastDate.add(6, "months");
        }

        let weeks: Array<YearWeekOption> = [];

        for (const day = moment(initialDate); day.isBefore(lastDate); day.add(1, "week")) {
            const firstDay = moment(day);

            weeks.push({
                // year: firstDay.add(6, "days").isoWeekYear(),
                // week: firstDay.add(-5, "days").isoWeek(),
                // startDay: firstDay.add(-1, "days").format("YYYY-MM-DD"),
                // finishDay: firstDay.add(6, "days").format("YYYY-MM-DD")
                year: firstDay.isoWeekYear(),
                week: firstDay.isoWeek(),
                startDay: firstDay.startOf("week").format("YYYY-MM-DD"),
                finishDay: firstDay.endOf("week").format("YYYY-MM-DD")
            });
        }

        return weeks;
    };

    checkIfCucumbersSelected = (): boolean => {
        const configuration = this.props.applicationStore!.configuration!;
        const submission = this.props.applicationStore!.submission! as GrowerSubmission;

        if (this.state.varietyId) {
            return configuration.commodities.some(
                (c: CommoditySquareFootCommodity) => c.id === this.state.varietyId && c.type === Commodity.CUCUMBER
            );
        } else if (this.state.proprietaryVarietyId) {
            return submission.proprietaryCommodities.some(c => c.id === this.state.proprietaryVarietyId && c.type === Commodity.CUCUMBER);
        } else {
            return false;
        }
    };

    checkIfSeedlessCucumbersSelected = (): boolean => {
        const configuration = this.props.applicationStore!.configuration!;
        const submission = this.props.applicationStore!.submission! as GrowerSubmission;

        if (this.state.varietyId) {
            return configuration.commodities.some(
                (c: CommoditySquareFootCommodity) => c.id === this.state.varietyId && (c.friendlyName === SEEDLESS_CUCUMBERS_NAME || c.friendlyName === ENGLISH_CUCUMBERS_NAME)
            );
        } else {
            return false;
        }
    };
    checkIf10LbsCaseRequired = (): boolean => {
        const configuration = this.props.applicationStore!.configuration!;
        const submission = this.props.applicationStore!.submission! as GrowerSubmission;

        if (this.state.varietyId) {
            return configuration.commodities.some(
                (c: CommoditySquareFootCommodity) => c.id === this.state.varietyId && c.type !== Commodity.CUCUMBER && (_10lbsUnits.indexOf(c.friendlyName) > -1)
            );
        } else {
            return false;
        }
    }
    checkIf75LbsCaseRequired = (): boolean => {
        const configuration = this.props.applicationStore!.configuration!;
        const submission = this.props.applicationStore!.submission! as GrowerSubmission;

        if (this.state.varietyId) {
            return configuration.commodities.some(
                (c: CommoditySquareFootCommodity) => c.id === this.state.varietyId && (_75lbsUnits.indexOf(c.friendlyName) > -1)
            );
        } else {
            return false;
        }
    }
    checkIf25LbsCaseRequired = (): boolean => {
        const configuration = this.props.applicationStore!.configuration!;
        const submission = this.props.applicationStore!.submission! as GrowerSubmission;

        if (this.state.varietyId) {
            return configuration.commodities.some(
                (c: CommoditySquareFootCommodity) => c.id === this.state.varietyId && (_25lbsUnits.indexOf(c.friendlyName) > -1)
            );
        } else {
            return false;
        }
    }
    varietyIdGenerator = (variety: CommoditySquareFootCommodity | ProprietaryCommodity) => {
        if ((variety as any).variety) {
            return `PROP_${variety.id}`;
        } else {
            return `NOTPROP_${variety.id}`;
        }
    };

    varietyIdToState = (id: string): Pick<Fields, "varietyId" | "proprietaryVarietyId"> => {
        const parts = id.split("_");
        if (parts[0] === "PROP") {
            return {varietyId: null, proprietaryVarietyId: parseInt(parts[1], 10)};
        } else if (parts[0] === "NOTPROP") {
            return {proprietaryVarietyId: null, varietyId: parseInt(parts[1], 10)};
        } else {
            console.warn("Invalid variety string ID");
            return {proprietaryVarietyId: null, varietyId: null};
        }
    };

    currentVarietyId = () => {
        if (this.state.varietyId) {
            return `NOTPROP_${this.state.varietyId}`;
        } else if (this.state.proprietaryVarietyId) {
            return `PROP_${this.state.proprietaryVarietyId}`;
        } else {
            return null;
        }
    };
    currentVariety = () => {
        if (this.props.applicationStore!.configuration === null || this.props.applicationStore!.configuration.commodities === null)
            return null;
        let filterResults = this.props.applicationStore!.configuration.commodities.filter(c => c.id === this.state.varietyId);
        if (filterResults.length === 0)
            return null;
        if (filterResults.length == 1)
            return filterResults[0] as CommoditySquareFootCommodity
        else
            return null;
    };
    filterDetails = (selectedVariety: CommoditySquareFootCommodity | null, detailType: CommodityDetailType) => {
        if (selectedVariety === null)
            return null;
        if (this.props.applicationStore!.configuration === null || this.props.applicationStore!.configuration.commodities === null)
            return null;
        let filterResults = selectedVariety.details.filter(c => c.detailType === detailType);
        if (filterResults.length === 0)
            return null;
        return filterResults;
    }


    yearWeekToString = (yearWeek: YearWeek): string => `${yearWeek.year}_${yearWeek.week}`;
    stringToYearWeek = (value: string): YearWeek => {
        const split = value.split("_");
        return {
            year: parseInt(split[0], 10),
            week: parseInt(split[1], 10)
        };
    };

    addAttachment = async (file: File, attachmentType: string) => {
        const fileBase64 = await FileToBase64(file);
        const request = {
            name: file.name,
            contentType: file.type,
            data: fileBase64,
            attachmentType
        };
        try {
            const s = this.state;
            let pFiles;
            if(s.proprietaryFiles != null && s.proprietaryFiles.length > 0){
                pFiles = s.proprietaryFiles;
            }
            else {
                pFiles = new Array<AttachmentRequest>();
            }
            pFiles.push(request);
                this.setState({proprietaryFiles: pFiles});
            // await this.props.applicationStore!.addAttachment(request);
            // successMessage(`${file.name} has been attached.`);
            // this.setState({newUpload: true});
        } catch (error) {
            errorMessage(error);
        }
    };
removeAttachment = async(idx: number)=> {
    const files = this.state.proprietaryFiles as Array<AttachmentRequest>;
    let newFiles = Array<AttachmentRequest>();
    for(let i=0; i < files.length; i=i+1)
    {
        if(i !== idx) {
            newFiles.push();
        }
    }
    this.setState({proprietaryFiles:newFiles});
}
    deleteAttachment = async (attachmentId: number) => {
        const goAhead = await SimpleConfirmation(`Are you sure you'd like to delete this attachment? This operation cannot be undone`);
        if (!goAhead) {
            return;
        }
        try {
            await this.props.applicationStore!.deleteAttachment(attachmentId);
            successMessage(`The attachment has been deleted.`);
        } catch (error) {
            errorMessage(error);
        }
    };

    getAttachment = async (fileName: string, attachmentId: number) => {
        try {
            const blob = await this.props.submissionEndpoints!.getAttachment(attachmentId);
            DownloadBlob(fileName, blob);
        } catch (error) {
            errorMessage(error);
        }
    };


    render() {
        const configuration = this.props.applicationStore!.configuration;
        if (!configuration) {
            return <div/>;
        }

        const submission = this.props.applicationStore!.submission as GrowerSubmission;
        if (!submission) {
            return <div/>;
        }

        const attachments = (this.props.applicationStore!.submission! as GrowerSubmission).growerAttachments;

        const loading = this.props.applicationStore!.loading;
        const currentVarietyId = this.currentVarietyId();
        const selectedVariety = this.currentVariety();
        const sizeDetails = this.filterDetails(selectedVariety, CommodityDetailType.SIZE);
        const colorDetails = this.filterDetails(selectedVariety, CommodityDetailType.COLOR);
        //removed as part of 2023/2024 licence updates.
        //const containerDetails =  this.filterDetails(selectedVariety, CommodityDetailType.CONTAINER);
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary" onClick={() => this.openDialog()}>
                            <FontAwesomeIcon icon={faCalendarPlus} className="mr-2"/>
                            Add Range
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FormDialog
                            title={`${this.state.id ? `Edit` : `Add New`} Range`}
                            modalSize="modal-xl"
                            show={this.state.showDialog}
                            onClose={() => this.closeDialog()}
                        >
                            <form
                                id="future-expansions-form"
                                ref={form => (this.form = form)}
                                className={"needs-validation" + (this.state.wasValidated ? " was-validated" : "")}
                                onSubmit={e => this.submit(e)}
                                noValidate={true}
                            >
                                <div className="form-row mb-2">
                                    <SelectGroup
                                        label="Commodity Category:"
                                        name="CommodityType"
                                        isRequired={true}
                                        // isInvalid={
                                        //     this.state.wasValidated && this.state.varietyId === null && this.state.proprietaryVarietyId === null
                                        // }
                                        className="col-md-12 col-lg-3"
                                        disabled={loading}
                                        value={this.state.varietyType || ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            const value = e.target.value;
                                            //const variety = this.varietyIdToState(value);
                                            const variety = value as Commodity;
                                            // const enteredExpectedYieldUnit =
                                            //     variety.varietyId !== null ? this.handleDefaultValueYieldUnit(variety.varietyId) : null;
                                            this.setState({
                                                varietyType: variety,
                                                varietyId: null,
                                                commoditySizeId: null,
                                                commodityColorId: null,
                                                commodityContainerId: null
                                            });
                                        }}
                                    >
                                        {currentVarietyId === null &&
                                            <option value="">Select a Commodity Category...</option>}
                                        {this.CommodityTypes().map((c) => (
                                            <option key={c} value={c}>
                                                {CommodityFriendlyName(c)}
                                            </option>
                                        ))}
                                    </SelectGroup>
                                    <SelectGroup
                                        label="Commodity Type:"
                                        name="variety"
                                        isRequired={true}
                                        isInvalid={
                                            this.state.wasValidated && this.state.varietyId === null && this.state.proprietaryVarietyId === null
                                        }
                                        className="col-md-12 col-lg-3"
                                        disabled={loading}
                                        value={currentVarietyId || ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            const value = e.target.value;
                                            const variety = this.varietyIdToState(value);
                                            const enteredExpectedYieldUnit =
                                                variety.varietyId !== null ? this.handleDefaultValueYieldUnit(variety.varietyId) : null;
                                            this.setState({...variety, enteredExpectedYieldUnit});
                                        }}
                                    >
                                        {currentVarietyId === null &&
                                            <option value="">Select a Commodity Type...</option>}

                                        {configuration.commodities.filter(c => c.type as Commodity === this.state.varietyType).map((c: CommoditySquareFootCommodity) => (
                                            <option key={c.id} value={this.varietyIdGenerator(c)}>
                                                {c.friendlyName}
                                            </option>
                                        ))}
                                    </SelectGroup>
                                    {/*COLOR DETAIL*/}
                                    {selectedVariety !== null && colorDetails !== null &&
                                        <SelectGroup
                                            label="Commodity Color:"
                                            name="commodityColor"
                                            isRequired={true}
                                            isInvalid={
                                                this.state.wasValidated && this.state.commodityColorId === null
                                            }
                                            className="col-md-12 col-lg-3"
                                            disabled={loading}
                                            value={this.state.commodityColorId || ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                const commodityColorId = e.target.value;

                                                // const enteredExpectedYieldUnit =
                                                //     variety.varietyId !== null ? this.handleDefaultValueYieldUnit(variety.varietyId) : null;
                                                this.setState({...{commodityColorId: commodityColorId}});
                                            }}
                                        >
                                            {this.state.commodityColorId === null &&
                                                <option value="">Select a Commodity Color...</option>}

                                            {colorDetails.map((c: CommodityDetail) => (
                                                <option key={c.id} value={c.id}>
                                                    {c.friendlyName}
                                                </option>
                                            ))}
                                        </SelectGroup>
                                    }
                                    {/*CONTAINER DETAIL Removed as part of 2023/2024 licenses */}
                                    {/*{selectedVariety !== null && containerDetails !== null &&*/}
                                    {/*    <SelectGroup*/}
                                    {/*        label="Commodity Container:"*/}
                                    {/*        name="commodityContainer"*/}
                                    {/*        isRequired={true}*/}
                                    {/*        isInvalid={*/}
                                    {/*            this.state.wasValidated && this.state.commodityContainerId === null*/}
                                    {/*        }*/}
                                    {/*        className="col-md-12 col-lg-3"*/}
                                    {/*        disabled={loading}*/}
                                    {/*        value={this.state.commodityContainerId || ""}*/}
                                    {/*        onChange={e => {*/}
                                    {/*            e.preventDefault();*/}
                                    {/*            const commodityContainerId = parseInt(e.target.value);*/}

                                    {/*            // const enteredExpectedYieldUnit =*/}
                                    {/*            //     variety.varietyId !== null ? this.handleDefaultValueYieldUnit(variety.varietyId) : null;*/}
                                    {/*            this.setState({...{commodityContainerId: commodityContainerId}});*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        {this.state.commodityContainerId === null &&*/}
                                    {/*            <option value="">Select a Commodity Container...</option>}*/}

                                    {/*        {containerDetails.map((c: CommodityDetail) => (*/}
                                    {/*            <option key={c.id} value={c.id}>*/}
                                    {/*                {c.friendlyName}*/}
                                    {/*            </option>*/}
                                    {/*        ))}*/}
                                    {/*    </SelectGroup>*/}
                                    {/*}*/}
                                    {/*SIZE DETAIL*/}
                                    {selectedVariety !== null && sizeDetails !== null &&
                                        <SelectGroup
                                            label="Commodity Size:"
                                            name="commoditySize"
                                            isRequired={true}
                                            isInvalid={
                                                this.state.wasValidated && this.state.commoditySizeId === null
                                            }
                                            className="col-md-12 col-lg-3"
                                            disabled={loading}
                                            value={this.state.commoditySizeId || ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                const commoditySizeId = parseInt(e.target.value);

                                                // const enteredExpectedYieldUnit =
                                                //     variety.varietyId !== null ? this.handleDefaultValueYieldUnit(variety.varietyId) : null;
                                                this.setState({...{commoditySizeId: commoditySizeId}});
                                            }}
                                        >
                                            {this.state.commoditySizeId === null &&
                                                <option value="">Select a Commodity Size...</option>}

                                            {sizeDetails.map((c: CommodityDetail) => (
                                                <option key={c.id} value={c.id}>
                                                    {c.friendlyName}
                                                </option>
                                            ))}
                                        </SelectGroup>
                                    }
                                    <InputGroup
                                        label={
                                            <>
                                                Range Name: <small>(Optional. Eg., Phase 1)</small>
                                            </>
                                        }
                                        className={`col-md-12 col-lg-3`}
                                        type="string"
                                        name="rangeName"
                                        isRequired={false}
                                        disabled={loading}
                                        value={this.state.rangeName || ""}
                                        onChange={e => this.setState({rangeName: e.target.value})}
                                    />
                                    <InputGroup
                                        label={
                                            <>
                                                Seedling Variety: <small>(E.g., Picowell)</small>
                                            </>
                                        }
                                        className={`col-md-12 col-lg-3`}
                                        type="string"
                                        name="seedlingVariety"
                                        isRequired={false}
                                        disabled={loading}
                                        value={this.state.seedlingVariety || ""}
                                        onChange={e => this.setState({seedlingVariety: e.target.value})}
                                    />
                                    <div className="col-md-12 col-lg-3 pt-4">
                                        <label htmlFor="organic" className="pt-2 ml-3">
                                            <input
                                                type="checkbox"
                                                id="organic"
                                                name="organic"
                                                className="mr-3"
                                                checked={this.state.organic}
                                                onChange={_ => this.setState({organic: !this.state.organic})}
                                                disabled={loading}
                                            />
                                            Organic
                                        </label>
                                    </div>
                                    <div className="col-md-12 col-lg-3 pt-4">
                                        <label htmlFor="proprietary" className="pt-2 ml-3">
                                            <input
                                                type="checkbox"
                                                id="proprietary"
                                                name="proprietary"
                                                className="mr-3"
                                                checked={this.state.proprietary}
                                                onChange={_ => this.setState({showPropDialog: !this.state.proprietary, proprietary: !this.state.proprietary })}
                                                disabled={loading}
                                            />
                                            Proprietary
                                        </label>
                                    </div>

                                </div>
                                <div className="form-row mt-2 mb-3">
                                    <InputGroup
                                        label="Area: Square Meters"
                                        className={'col-md-12 col-lg-4'}
                                        type="number"
                                        name="enteredAreaSqM"
                                        isRequired={true}
                                        disabled={loading}
                                        value={(
                                            (this.state.enteredArea && this.state.preferredUnit) ? (this.state.preferredUnit == 'sqm' ? this.state.enteredArea :
                                                    (parseFloat(this.state.enteredArea.replace(',', '')) *
                                                        configuration.areaUnits.find(a => a.name === this.state.preferredUnit)!.squareFeet
                                                        / configuration.areaUnits.find(a => a.name === 'sqm')!.squareFeet
                                                    ).toFixed(2)
                                            ) : ""
                                        )}
                                        onChange={e => this.setState({
                                            enteredArea: e.target.value,
                                            preferredUnit: 'sqm'
                                        })}
                                    />
                                    <InputGroup
                                        label="Area: Square Feet"
                                        className={'col-md-12 col-lg-4'}
                                        type="number"
                                        name="enteredAreaSqFeet"
                                        isRequired={true}
                                        disabled={loading}
                                        value={(
                                            (this.state.enteredArea && this.state.preferredUnit) ? (this.state.preferredUnit == 'sqft' ? this.state.enteredArea :
                                                    (parseFloat(this.state.enteredArea.replace(',', '')) *
                                                        configuration.areaUnits.find(a => a.name === this.state.preferredUnit)!.squareFeet
                                                        / configuration.areaUnits.find(a => a.name === 'sqft')!.squareFeet
                                                    ).toFixed(2)
                                            ) : ""
                                        )}
                                        onChange={e => this.setState({
                                            enteredArea: e.target.value,
                                            preferredUnit: 'sqft'
                                        })}
                                    />
                                    <InputGroup
                                        label="Area: Acres"
                                        className={'col-md-12 col-lg-4'}
                                        type="number"
                                        name="enteredAreaAcres"
                                        isRequired={true}
                                        disabled={loading}
                                        value={(
                                            (this.state.enteredArea && this.state.preferredUnit) ? (this.state.preferredUnit == 'acres' ? this.state.enteredArea :
                                                    (parseFloat(this.state.enteredArea) *
                                                        configuration.areaUnits.find(a => a.name === this.state.preferredUnit)!.squareFeet
                                                        / configuration.areaUnits.find(a => a.name === 'acres')!.squareFeet
                                                    ).toFixed(2)
                                            ) : ""
                                        )}
                                        onChange={e => this.setState({
                                            enteredArea: e.target.value,
                                            preferredUnit: 'acres'
                                        })}
                                    />

                                    <SelectGroup
                                        label="Unit:"
                                        name="preferredUnit"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && (this.state.preferredUnit === null || this.state.preferredUnit === "")}

                                        className="col-md-12 col-lg-4 hidden d-none"
                                        disabled={loading}
                                        value={this.state.preferredUnit || ""}
                                        onChange={e => {
                                            this.setState({preferredUnit: e.target.value});
                                        }}
                                    >
                                        <option value="">Select a unit...</option>
                                        {configuration.areaUnits.map((a: AreaUnit) => (
                                            <option key={a.name} value={a.name}>
                                                {a.friendlyName}
                                            </option>
                                        ))}
                                    </SelectGroup>
                                    {this.state.enteredArea && this.state.preferredUnit && (
                                        <div className="col-4">
                                            <label htmlFor="">Normalized Area in Square Feet:</label>
                                            <input
                                                type="text"
                                                className="form-control d-none"
                                                readOnly={true}
                                                value={(
                                                    parseFloat(this.state.enteredArea) *
                                                    configuration.areaUnits.find(a => a.name === this.state.preferredUnit)!.squareFeet
                                                ).toLocaleString()}
                                            />
                                        </div>
                                    )}
                                </div>
                                <hr/>
                                <div className={`form-row ${this.state.isRotation ? "bg-warning" : ""} py-3`}>
                                    <SelectGroup
                                        label="Plant Week:"
                                        name="plantYearWeek"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && this.state.plantYearWeek === null}
                                        className="col-md-12 col-lg-4"
                                        disabled={loading}
                                        value={this.state.plantYearWeek ? this.yearWeekToString(this.state.plantYearWeek) : ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            const plantYearWeek = e.target.value === "" ? null : this.stringToYearWeek(e.target.value);
                                            const pullYearWeek = (() => {
                                                if (plantYearWeek === null) {
                                                    return null;
                                                }
                                                if (this.state.pullYearWeek === null) {
                                                    return null;
                                                }
                                                if (YearWeekToMoment(plantYearWeek).isSameOrAfter(YearWeekToMoment(this.state.pullYearWeek))) {
                                                    return null;
                                                }
                                                return this.state.pullYearWeek;
                                            })();
                                            this.setState({plantYearWeek, pullYearWeek});
                                        }}
                                    >
                                        {this.state.plantYearWeek === null &&
                                            <option value="">Select a plant week...</option>}
                                        {this.generateWeekOptions()
                                            // .sort((a, b) => new Date(a.startDay).getTime() - new Date(b.startDay).getTime())
                                            .map(w => (
                                                <option key={w.week + "_" + w.year} value={w.year + "_" + w.week}>
                                                    Week
                                                    #{w.week.toString().length === 1 ? "0" + w.week : w.week}, {w.year} (
                                                    {moment(w.startDay).format("MMM DD")} - {moment(w.finishDay).format("DD")})
                                                </option>
                                            ))}
                                    </SelectGroup>
                                    {/* Todo -> Check if is Required*/}
                                    <SelectGroup
                                        label="Expected Harvest Week:"
                                        name="harvestYearWeek"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && this.state.harvestYearWeek === null}
                                        className="col-md-12 col-lg-4"
                                        disabled={this.state.plantYearWeek === null || loading}
                                        value={this.state.harvestYearWeek ? this.yearWeekToString(this.state.harvestYearWeek) : ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            const harvestYearWeek = e.target.value === "" ? null : this.stringToYearWeek(e.target.value);
                                            const pullYearWeek = (() => {
                                                if (harvestYearWeek === null) {
                                                    return null;
                                                }
                                                if (this.state.pullYearWeek === null) {
                                                    return null;
                                                }
                                                if (YearWeekToMoment(harvestYearWeek).isSameOrAfter(YearWeekToMoment(this.state.pullYearWeek))) {
                                                    return null;
                                                }
                                                return this.state.pullYearWeek;
                                            })();
                                            this.setState({harvestYearWeek, pullYearWeek});
                                        }}
                                    >
                                        <option value="">Select a Harvest week...</option>
                                        {/* {this.generateOptionWeeks(
                                            this.state.plantYearWeek
                                                ? { year: this.state.plantYearWeek!.year, week: this.state.plantYearWeek!.week + 1 }
                                                : null
                                        ) */}
                                        {this.state.plantYearWeek &&
                                            this.generateWeekOptions(this.state.plantYearWeek, true)
                                                // .sort((a, b) => new Date(a.startDay).getTime() - new Date(b.startDay).getTime())
                                                .map(w => (
                                                    <option key={w.week + "_" + w.year} value={w.year + "_" + w.week}>
                                                        Week
                                                        #{w.week.toString().length === 1 ? "0" + w.week : w.week}, {w.year} (
                                                        {moment(w.startDay).format("MMM DD")} - {moment(w.finishDay).format("DD")})
                                                    </option>
                                                ))}
                                    </SelectGroup>
                                    {/** Todo -> Check if need be after a Havest Week */}
                                    <SelectGroup
                                        label="Pull Week:"
                                        name="pullYearWeek"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && this.state.pullYearWeek === null}
                                        className="col-md-12 col-lg-4"
                                        disabled={this.state.harvestYearWeek === null || loading}
                                        value={this.state.pullYearWeek ? this.yearWeekToString(this.state.pullYearWeek) : ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            const pullYearWeek = e.target.value === "" ? null : this.stringToYearWeek(e.target.value);
                                            this.setState({pullYearWeek});
                                        }}
                                    >
                                        {this.state.pullYearWeek === null &&
                                            <option value="">Select a pull week...</option>}
                                        {this.state.plantYearWeek &&
                                            this.generateWeekOptions(this.state.harvestYearWeek, true).map(w => (
                                                <option key={w.year + "_" + w.week} value={w.year + "_" + w.week}>
                                                    Week
                                                    #{w.week.toString().length === 1 ? "0" + w.week : w.week}, {w.year} (
                                                    {moment(w.startDay).format("MMM DD")} - {moment(w.finishDay).format("DD")})
                                                </option>
                                            ))}
                                    </SelectGroup>
                                </div>
                                <hr/>
                                {this.checkIfCucumbersSelected() && (
                                    <div className="form-row my-3">
                                        <InputGroup
                                            label="Number of Heads/Plant"
                                            className={`col-md-12 col-lg-4`}
                                            inputClassName={``}
                                            type="number"
                                            name="headsPerPlant"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.numberOfHeadsPerPlant || ""}
                                            onChange={e => this.setState({numberOfHeadsPerPlant: e.target.value})}
                                        />
                                        <div className="col-md-12 col-lg-4">
                                            <label htmlFor="">
                                                Growing Method: <span className="text-danger">*</span>
                                            </label>
                                            <div>
                                                <label htmlFor="cucumberHighwire" className="pt-2 ml-3 d-flex">
                                                    <input
                                                        type="checkbox"
                                                        className="mr-3"
                                                        name="cucumberHighwire"
                                                        id="cucumberHighwire"
                                                        checked={this.state.cucumberHighwire}
                                                        onChange={e => this.setState({cucumberHighwire: !this.state.cucumberHighwire})}
                                                        disabled={loading}
                                                    />
                                                    High-wire
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="form-row my-3">
                                    <InputGroup
                                        label="Total Expected Yield for this rotation (per acre)"
                                        className={`col-md-12 col-lg-4`}
                                        type="number"
                                        name="enteredExpectedYield"
                                        isRequired={true}
                                        disabled={loading}
                                        value={this.state.enteredExpectedYield || ""}
                                        onChange={e => this.setState({enteredExpectedYield: e.target.value})}
                                    />
                                    <SelectGroup
                                        label="Unit:"
                                        name="enteredExpectedYieldUnit"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && this.state.enteredExpectedYieldUnit === null}
                                        className="col-md-12 col-lg-4"
                                        disabled={(this.state.varietyId === null && this.state.proprietaryVarietyId === null) || loading}
                                        value={this.state.enteredExpectedYieldUnit || ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            const enteredExpectedYieldUnit = e.target.value === "" ? null : e.target.value;
                                            this.setState({enteredExpectedYieldUnit});
                                        }}
                                    >
                                        {this.state.enteredExpectedYieldUnit === null &&
                                            <option value="">Select a unit...</option>}

                                        {this.checkIfSeedlessCucumbersSelected()
                                            ? configuration.yieldUnits
                                                .filter(u => u.name === "dzs")
                                                .map((a: YieldUnit) => (
                                                    <option key={a.name} value={a.name}>
                                                        {a.friendlyName}
                                                    </option>
                                                ))
                                            : configuration.yieldUnits
                                                .filter(u => u.name !== "dzs"
                                                    && (this.checkIf75LbsCaseRequired() ? true : u.name !== "7.5lb")
                                                    && (this.checkIf10LbsCaseRequired() ? true : u.name !== "10lb")
                                                    && (this.checkIf25LbsCaseRequired() ? true : u.name !== "25lb")
                                                )
                                                .map((a: YieldUnit) => (
                                                    <option key={a.name} value={a.name}>
                                                        {a.friendlyName}
                                                    </option>
                                                ))}

                                    </SelectGroup>
                                    {this.state.enteredExpectedYield && this.state.enteredExpectedYieldUnit && (
                                        <div className="col-4">
                                            <label htmlFor="">
                                                Normalized Yield
                                                in {this.checkIfSeedlessCucumbersSelected() ? "Dozens" : "Pounds"}:
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly={true}
                                                disabled={loading}
                                                value={(
                                                    parseFloat(this.state.enteredExpectedYield || "") *
                                                    configuration.yieldUnits.find(a => a.name === this.state.enteredExpectedYieldUnit)!.pounds
                                                ).toLocaleString()}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="form-row my-3">
                                    <SelectGroup
                                        label="Primary Propagator:"
                                        name="type"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && this.state.primaryPropagatorId === null}
                                        className="col-md-12 col-lg-4"
                                        disabled={loading}
                                        value={this.state.primaryPropagatorId || ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            const primaryPropagator = e.target.value === "" ? null : e.target.value;
                                            this.setState({primaryPropagatorId: primaryPropagator});
                                        }}
                                    >
                                        <option value="">Select a propagator...</option>
                                        {configuration.propagators.map((p: Propagator) => (
                                            <option key={p.id} value={p.id}>
                                                {p.friendlyName}
                                            </option>
                                        ))
                                        }
                                    </SelectGroup>
                                    <InputGroup
                                        label="Number of Plants per acre"
                                        className={`col-md-12 col-lg-4`}
                                        type="number"
                                        name="numberOfPlants"
                                        isRequired={true}
                                        disabled={loading}
                                        value={this.state.numberOfPlants || ""}
                                        onChange={e => this.setState({numberOfPlants: e.target.value})}
                                    />
                                    <SelectGroup
                                        label="Is this crop area under lights"
                                        name="underLights"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && (this.state.underLights === null) }
                                        className="col-md-12 col-lg-4"
                                        disabled={loading}
                                        value={(this.state.underLights !== null ? this.state.underLights.toString() : "")}
                                        onChange={e => {
                                            this.setState({underLights: e.target.value === "" ? null : e.target.value});
                                        }}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </SelectGroup>
                                </div>
                                <div className="form-row">
                                    <TextareaGroup
                                        label="Notes"
                                        rows={2}
                                        isRequired={false}
                                        name="notes"
                                        className="form-group col-12"
                                        value={this.state.notes || ""}
                                        onChange={e => this.setState({notes: e.target.value})}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="for-row text-right">
                                    <div className="col-12 pr-0">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.clearForm();
                                            }}
                                            disabled={loading}
                                        >
                                            Cancel
                                        </button>
                                        {!this.state.id && (
                                            <button
                                                type="button"
                                                onClick={() => this.createNewRotationWithSameRange()}
                                                className="btn btn-info mr-0 ml-3"
                                                disabled={loading}
                                            >
                                                <FontAwesomeIcon icon={faRedoAlt} className="mr-2"/>
                                                Save Range &amp; Create a New Rotation
                                            </button>
                                        )}
                                        <button type="submit" className="btn btn-primary mr-0 ml-3">
                                            {this.state.id ? (
                                                <>
                                                    <FontAwesomeIcon icon={faEdit} className="mr-2"/> Edit
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faPlus} className="mr-2"/> Save
                                                </>
                                            )}{" "}
                                            Range
                                        </button>
                                        {loading && <LoadingWidget className="ml-4"/>}
                                    </div>
                                </div>
                            </form>
                        </FormDialog>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FormDialog
                            title={`Proprietary Vegetable Variety.`}
                            modalSize="modal-xl"
                            show={this.state.showPropDialog}
                            onClose={() => this.closePropDialog()}
                        >
                            <div>
                                <div className="row col-lg-12">
                                    <strong>As per the 2023/24 Regulations, "proprietary greenhouse vegetable variety"
                                        means a variety of greenhouse vegetable approved by the local board to which one
                                        individual(s), business(es) or company(ies) has (have) exclusive rights</strong>
                                    <br/>
                                    <strong>Please upload your confirmation of proprietary ownership.
                                        This also includes all private label contracts.
                                        Your documents will be submitted to the OGVG for review and if your application
                                        is approved you will be notified directly.
                                        If you have any questions or concerns, please contact the OGVG office.</strong>
                                </div>
                                <form
                                    id="proprietary-popup-form"
                                    ref={form => (this.proprietaryForm = form)}
                                    className={"needs-validation" + (this.state.wasValidated ? " was-validated" : "")}
                                    onSubmit={e => this.submitProprietary(e)}
                                    noValidate={true}
                                >
                                    <div className="form-row mb-2">
                                        <SelectGroup
                                            label="Select Existing Proprietary Variant:"
                                            name="proprietaryVarietyId"
                                            className="col-md-12 col-lg-6"
                                            disabled={loading}
                                            value={(this.state.proprietaryVarietyId ? this.state.proprietaryVarietyId.toString() : "")}
                                            onChange={e => {
                                                this.setState({proprietaryVarietyId: e.target.value === "" ? null : e.target.value});
                                            }}
                                        >
                                            <option value="">Select a Preexisting Variety...</option>
                                            {submission.proprietaryCommodities.filter(c => c.applicationSourced)
                                               .map((c: ProprietaryCommodity) => (
                                                <option key={c.id} value={c.id}>
                                                    {c.variety}
                                                </option>
                                            ))}
                                        </SelectGroup>
                                        <div className="form-group col-md-12 col-lg-2">
                                        <label>&nbsp;</label>
                                        <button
                                            type="button"
                                            onClick={() => this.closePropDialogPriorUpload()}
                                            className="btn btn-info mr-0 ml-3 form-control"
                                            disabled={loading}
                                        >
                                            Select Variant.
                                        </button>
                                        </div>
                                    </div>
                                    <div className="form-row mb-2">
                                        <h4>
                                        <strong>Add new Variant</strong>
                                        </h4>
                                    </div>
                                    <div className="form-row mb-2">
                                        <InputGroup
                                            label="Seed Company Provider"
                                            className={`col-md-12 col-lg-6`}
                                            type="string"
                                            name="seedCompanyProvider"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.seedCompanyProvider || ""}
                                            onChange={e => this.setState({seedCompanyProvider: e.target.value})}
                                        />
                                        <InputGroup
                                            label="Seed Company Provider Main Contact"
                                            className={`col-md-12 col-lg-6`}
                                            type="string"
                                            name="seedCompanyProviderMainContact"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.seedCompanyProviderMainContact || ""}
                                            onChange={e => this.setState({seedCompanyProviderMainContact: e.target.value})}
                                        />
                                        <InputGroup
                                            label="Seed Company Phone Number"
                                            className={`col-md-12 col-lg-6`}
                                            type="tel"
                                            name="seedCompanyPhone"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.seedCompanyPhoneNumber || ""}
                                            onChange={e => this.setState({seedCompanyPhoneNumber: e.target.value})}
                                        />
                                        <InputGroup
                                            label="Seed Company Email Address"
                                            className={`col-md-12 col-lg-6`}
                                            type="email"
                                            name="seedCompanyEmail"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.seedCompanyEmailAddress || ""}
                                            onChange={e => this.setState({seedCompanyEmailAddress: e.target.value})}
                                        />
                                        <InputGroup
                                            label="Seed Company Address"
                                            className={`col-md-12 col-lg-12`}
                                            type="string"
                                            name="seedCompanyAddress"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.seedCompanyAddress || ""}
                                            onChange={e => this.setState({seedCompanyAddress: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-row mb-2">
                                        <InputGroup
                                            label="Proprietary Variety Name"
                                            className={`col-md-12 col-lg-6`}
                                            type="string"
                                            name="proprietaryVarietyName"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.variety || ""}
                                            onChange={e => this.setState({variety: e.target.value})}
                                        />
                                        <InputGroup
                                            label="Proprietary Code #"
                                            className={`col-md-12 col-lg-6`}
                                            type="string"
                                            name="proprietaryCode"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.proprietaryCode || ""}
                                            onChange={e => this.setState({proprietaryCode: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-row mb-2">
                                        <SelectGroup
                                            label=" Is this seed variety protected under the plant variety protection (PVP) act or have Patent rights been documented?"
                                            name="protectedVariety"
                                            isRequired={true}
                                            isInvalid={this.state.wasValidated && (this.state.protectedVariety === null)}
                                            className="col-md-12 col-lg-6"
                                            disabled={loading}
                                            value={(this.state.protectedVariety ? this.state.protectedVariety.toString() : "")}
                                            onChange={e => {
                                                this.setState({protectedVariety: e.target.value === "" ? null : e.target.value});
                                            }}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </SelectGroup>
                                    </div>
                                    <div className="form-row mb-2">

                                        <SelectGroup
                                            label="Will this seed variety be exclusive to ONLY the farm registering the proprietary commodities?"
                                            name="exclusiveCommodity"
                                            isRequired={true}
                                            isInvalid={this.state.wasValidated && (this.state.exclusiveCommodity === null)}
                                            className="col-md-12 col-lg-6"
                                            disabled={loading}
                                            value={(this.state.exclusiveCommodity ? this.state.exclusiveCommodity.toString() : "")}
                                            onChange={e => {
                                                this.setState({exclusiveCommodity: e.target.value === "" ? null : e.target.value});
                                            }}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </SelectGroup>
                                        { this.state.exclusiveCommodity &&
                                            <TextareaGroup
                                                label="Notes"
                                                rows={2}
                                                isRequired={true}
                                                name="exclusivityNotes"
                                                className="form-group col-md-12  col-lg-6"
                                                value={this.state.exclusivityNotes || ""}
                                                onChange={e => this.setState({exclusivityNotes: e.target.value})}
                                                disabled={loading}
                                            />
                                        }
                                    </div>
                                    <div className="form-row mb-2">
                                    <SelectGroup
                                        label="Has a contractual agreement been established?"
                                        name="contractEstablished"
                                        isRequired={true}
                                        isInvalid={this.state.wasValidated && (this.state.contractEstablished === null)}
                                        className="col-md-12 col-lg-6"
                                        disabled={loading}
                                        value={(this.state.contractEstablished ? this.state.contractEstablished.toString() : "")}
                                        onChange={e => {
                                            this.setState({contractEstablished: e.target.value === "" ? null : e.target.value});
                                        }}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </SelectGroup>
                                        <InputGroup
                                            label="What is the time frame (in Months) for the contract?"
                                            className={`col-md-12 col-lg-6`}
                                            type="number"
                                            name="contractTimeFrameMonths"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.contractTimeFrameMonths || ""}
                                            onChange={e => this.setState({contractTimeFrameMonths: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-row mb-2">

                                        <InputGroup
                                            label="How many acres will be grown with this proprietary seed this year?"
                                            className={`col-md-12 col-lg-6`}
                                            type="number"
                                            name="annualAcresToSeed"
                                            isRequired={true}
                                            disabled={loading}
                                            value={this.state.annualAcresToSeed || ""}
                                            onChange={e => this.setState({annualAcresToSeed: e.target.value})}
                                        />
                                        <SelectGroup
                                            label="Can this agreement be transferred or provided to any other party for crop breeding, research or seed production?:"
                                            name="transferrableAgreement"
                                            isRequired={true}
                                            isInvalid={this.state.wasValidated && (this.state.transferrableAgreement === null )}
                                            className="col-md-12 col-lg-6"
                                            disabled={loading}
                                            value={(this.state.transferrableAgreement ? this.state.transferrableAgreement.toString() : "")}
                                            onChange={e => {
                                                this.setState({transferrableAgreement: e.target.value === "" ? null : e.target.value});
                                            }}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </SelectGroup>
                                    </div>


                                <div className="row">
                                    <div className="col-md-12 col-lg-8 mx-auto">
                                        {/*<AttachmentWidget*/}
                                        {/*    attachments={attachments.proprietaryCropCert}*/}
                                        {/*    addAttachment={file => this.addAttachment(file, "proprietaryCropCert")}*/}
                                        {/*    deleteAttachment={this.deleteAttachment}*/}
                                        {/*    getAttachment={this.getAttachment}*/}
                                        {/*/>*/}
                                        <Dropzone
                                            onDrop={async acceptedFiles => {
                                                //this.setState({ loading: true });
                                                try {
                                                    for (let i = 0; i < acceptedFiles.length; i++) {
                                                        await this.addAttachment(acceptedFiles[i],"proprietaryCropCert");
                                                    }
                                                } finally {
                                                    //this.setState({ loading: false });
                                                }
                                            }}
                                            multiple={false}
                                            disabled={false}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()} className="py-3 rounded attachmentLog">
                                                        <input {...getInputProps()} />
                                                        <p className="text-center text-secondary mb-1">
                                                            {!loading && (
                                                                <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" className="mt-2" style={{ opacity: 0.6 }} />
                                                            )}
                                                            {loading && <LoadingWidget />}
                                                        </p>
                                                        <p className="text-center text-secondary" style={{ opacity: 0.6 }}>
                                                            Drag &amp; drop here or click to upload files
                                                        </p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <div className="form-row">
                                            <div className="form-group col-sm-12 col-md-12 mt-3">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th>File Name</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.proprietaryFiles.length === 0 ? (
                                                        <tr>
                                                            <td colSpan={2} align="center">
                                                                <span>No files attached.</span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        this.state.proprietaryFiles.map((attachment, idx) => {
                                                            return (
                                                                <tr key={idx}>
                                                                    <td>{attachment.name}</td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-dark btn-sm"
                                                                                id={`tooltipDelete_${idx}`}
                                                                                title="Delete File"
                                                                                onClick={() => this.removeAttachment(idx)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                                            </button>
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                target={`tooltipDelete_${idx}`}
                                                                                delay={0}
                                                                                className="tooltipstrap"
                                                                            >
                                                                                Delete File
                                                                            </UncontrolledTooltip>
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                    <button type="submit" className="btn btn-primary mr-0 ml-3">
                                        {this.state.proprietaryFieldId ? (
                                            <>
                                                <FontAwesomeIcon icon={faEdit} className="mr-2"/> Edit
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faPlus} className="mr-2"/> Save
                                            </>
                                        )}{" "}
                                        Propriety Information
                                    </button>
                                    {loading && <LoadingWidget className="ml-4"/>}
                                </form>

                            </div>
                        </FormDialog>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CropProductionDetailsTableWidget
                            deleteCropProduction={cropId => this.deleteCropProduction(cropId)}
                            editCropProduction={crop => {
                                this.editCropProductionForm(crop);
                            }}
                            createNewRotationWithSameRange={crop => this.editCropProductionWithSameRangeForm(crop)}
                        />
                    </div>
                </div>
            </>
        );
    }
}

import { CalcPercentage } from "../../assets/Common/FileUtil";
import { GrowerSubmission, Submission } from "../Submission";
import { BuildConfirmUpdateOneToManyValidityCheck, BuildSimpleFieldValidityCheck } from "./Validation";
import {
    CalculateOneToManyValidationState,
    CalculateSingleValueValidationState,
    ConfirmUpdateValidity,
    GrowerValidation,
    BuildConfirmUpdateSingleValueValidityCheck
} from "./Validation";
import moment from "moment";
import {isEmptyObject} from "jquery";

export const GrowerValidationRules: GrowerValidation = {
    form2: {
        name: "Form 2 - Producer's Information",
        subsections: {
            producersInformation: {
                name: "Producer's Information",
                checks: [
                    BuildConfirmUpdateSingleValueValidityCheck("legalMemberName", "Missing legal member name"),
                    BuildSimpleFieldValidityCheck("partTimeProducer", "Missing whether this is a part-time producer"),
                    BuildSimpleFieldValidityCheck("ownAnyOtherGreenhouses", "Missing whether any other greenhouses are owned"),
                    BuildSimpleFieldValidityCheck("singleFBRAgriCorp", "Missing whether this is a single FBR/Agricorp")
                ]
            },
            mailingAddress: {
                name: "Mailing Address",
                checks: [BuildConfirmUpdateSingleValueValidityCheck("mailingAddress", "Missing one or more fields for the mailing address", ['commodities','totalSquareFeet'])]
            },
            physicalAddresses: {
                name: "Physical Address(es)",
                checks: [BuildConfirmUpdateOneToManyValidityCheck("physicalAddresses", "Missing one or more fields for a physical address")]
            },
            contacts: {
                name: "Contacts",
                checks: [
                    BuildConfirmUpdateOneToManyValidityCheck("ownerContact", "Missing one or more fields for an owner contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("primaryContact", "Missing one or more fields for a primary contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("foodSafetyContact", "Missing one or more fields for a food safety contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("biosecurityContact", "Missing one or more fields for a biosecurity contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("growerContact", "Missing one or more fields for a grower contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("cropConsultantContact", "Missing one or more fields for a crop consultant contact"),
                    BuildConfirmUpdateOneToManyValidityCheck("humanResourcesContact", "Missing one or more fields for a human resources contact")
                ]
            },
            traceabilityCodes: {
                name: "Traceability Codes",
                checks: [
                    BuildConfirmUpdateOneToManyValidityCheck("growerPackerTraceability", "Missing one or more fields for a packer traceability code"),
                    BuildConfirmUpdateOneToManyValidityCheck(
                        "growerMarketerTraceability",
                        "Missing one or more fields for a marketer traceability code"
                    ),
                    {
                        isValid: (s: Submission) => {
                            const submission = s as GrowerSubmission;
                            return submission.confirmUpdates.growerPackerTraceability.outcome === "UPDATED"
                                ? CalcPercentage(
                                      Object.keys(submission.confirmUpdates.growerPackerTraceability.updated || {}).map(key =>
                                          submission.confirmUpdates.growerPackerTraceability.updated![key].discarded
                                              ? "0"
                                              : submission.confirmUpdates.growerPackerTraceability.updated![key].percentage
                                      )
                                  ) === 100
                                : true;
                        },
                        message: (s: Submission) => {
                            const submission = s as GrowerSubmission;
                            const ok =
                                submission.confirmUpdates.growerPackerTraceability.outcome === "UPDATED"
                                    ? CalcPercentage(
                                          Object.keys(submission.confirmUpdates.growerPackerTraceability.updated || {}).map(key =>
                                              submission.confirmUpdates.growerPackerTraceability.updated![key].discarded
                                                  ? "0"
                                                  : submission.confirmUpdates.growerPackerTraceability.updated![key].percentage
                                          )
                                      ) === 100
                                    : true;
                            return ok ? "" : "Packer traceability percentages do not sum to 100%";
                        }
                    },
                    {
                        isValid: (s: Submission) => {
                            const submission = s as GrowerSubmission;
                            return submission.confirmUpdates.growerMarketerTraceability.outcome === "UPDATED"
                                ? CalcPercentage(
                                      Object.keys(submission.confirmUpdates.growerMarketerTraceability.updated || {}).map(key =>
                                          submission.confirmUpdates.growerMarketerTraceability.updated![key].discarded
                                              ? "0"
                                              : submission.confirmUpdates.growerMarketerTraceability.updated![key].percentage
                                      )
                                  ) === 100
                                : true;
                        },
                        message: (s: Submission) => {
                            const submission = s as GrowerSubmission;
                            const ok =
                                submission.confirmUpdates.growerMarketerTraceability.outcome === "UPDATED"
                                    ? CalcPercentage(
                                          Object.keys(submission.confirmUpdates.growerMarketerTraceability.updated || {}).map(key =>
                                              submission.confirmUpdates.growerMarketerTraceability.updated![key].discarded
                                                  ? "0"
                                                  : submission.confirmUpdates.growerMarketerTraceability.updated![key].percentage
                                          )
                                      ) === 100
                                    : true;
                            return ok ? "" : "Marketer traceability percentages do not sum to 100%";
                        }
                    }
                ]
            },
            futureExpansions: {
                name: "Future Expansions",
                checks: [
                    BuildSimpleFieldValidityCheck("expandInNextYear", "Missing whether you will be expanding in the next year"),
                    BuildSimpleFieldValidityCheck("expandInCanada", "Missing whether you are planning an expansion in Canada in the next 5 years"),
                    BuildSimpleFieldValidityCheck("expandInUSA", "Missing whether you are planning an expansion in the USA in the next 5 years")
                ]
            }
        }
    },
    form2A: {
        name: "Form 2A - Greenhouse Operation Information",
        subsections: {
            details: {
                name: "Form 2A - Greenhouse Operation Information",
                checks: [
                    // {
                    //     isValid: (s: Submission) => {
                    //         return ValidateBusinessOverview(submission);
                    //     },
                    //     message: (s: Submission) => {
                    //         return (s as GrowerSubmission).cropProductionDetails.length > 0 ? "At least one crop must be specified" : "";
                    //     }
                    // }
                ]
            }
        }
    },
    CropProduction: {
        name: "Crop Production Details",
        subsections: {
            details: {
                name: "Crop Production Details",
                checks: [
                    {
                        isValid: (s: Submission) => {
                            let details = (s as GrowerSubmission).cropProductionDetails;
                            if(isEmptyObject(details) || details.length === 0) return false;
                            let maxPullCrop = details.reduce(function (a, b)
                            {
                                let aMoment =moment()
                                    .isoWeekYear( a.pullYearWeek.year)
                                    .isoWeek( a.pullYearWeek.week)
                                    .startOf("week");
                                let bMoment = moment()
                                    .isoWeekYear( b.pullYearWeek.year)
                                    .isoWeek( b.pullYearWeek.week)
                                    .startOf("week");
                                return aMoment > bMoment ? a : b;

                            });
                            let maxPullMoment =  moment()
                                .isoWeekYear( maxPullCrop.pullYearWeek.year)
                                .isoWeek( maxPullCrop.pullYearWeek.week)
                                .startOf("week");
                            let licenceYear = moment()
                                .year(moment(s.licenceYear.endDate).year())
                                .month(10) // November.
                                .date(30);
                            return licenceYear <= maxPullMoment
                        },
                        message: (s: Submission) => {
                            let details = (s as GrowerSubmission).cropProductionDetails;
                            let maxPullCrop = details.reduce(function (a, b) { return a.pullYearWeek.year > b.pullYearWeek.year ? a : a.pullYearWeek.week > b.pullYearWeek.week ? a : b; });
                            let maxPullMoment =  moment()
                                .isoWeekYear( maxPullCrop.pullYearWeek.year)
                                .isoWeek( maxPullCrop.pullYearWeek.week)
                                .startOf("week")
                            let licenceYear = moment()
                                .year(moment(s.licenceYear.endDate).year())
                                .month(10) // November.
                                .date(30);
                            return  licenceYear <= maxPullMoment ? "We require crop production details until at least November 30th of " + licenceYear.year() + ". If your production does not go to November 30th of " + licenceYear.year() + "  please contact Kathy at k.reeb@ontariogreenhouse.com" : "";
                        }
                    }
                ]
            }
        }
    }
};
